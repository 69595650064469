function loadPosts(cat_name, posts, tag, page, more = false) {
  var data_posts = posts != null ? posts : null;
  var data_cat_name = cat_name != null ? cat_name : null;
  var data_tag = tag != null ? tag : null;
  var data_page = page != null ? page : null;
  jQuery.ajax({
    url: latu_vars.ajaxurl,
    type: "post",
    data: {
      action: "latu_ajax_getPosts",
      posts: data_posts,
      cat_name: data_cat_name,
      tag: data_tag,
      page: data_page
    },
    beforeSend: function () {
      console.log("loading");
      if (more == false) {
        jQuery("#posts-container").empty();
      }
      jQuery(".posts__loading").show();
    },
    success: function (data) {
      console.log("success");
      if (more == false) {
        jQuery("#posts-container").html(data);
      } else {
        jQuery(".posts__more").remove();
        jQuery("#posts-container").append(data);
      }
      jQuery(".posts__loading").hide();
    },
    error: function () {
      jQuery(".posts__loading").html("There was an error loading the posts");
    }
  });
}

// function scrollW() {
//   //check scroll down or up for header styles
//   let st = window.pageYOffset || document.documentElement.scrollTop;
//   //let lastScrollTop;
//   if (st > 80) {
//     if (st > lastScrollTop) {
//       // downscroll code
//       if (!document.getElementById('header').classList.contains('down')) {
//         document.getElementById('header').classList.add('down');
//         document.getElementById('header').classList.remove('up');
//       }
//     } else {
//       // upscroll code
//       if (!document.getElementById('header').classList.contains('up')) {
//         document.getElementById('header').classList.add('up');
//         document.getElementById('header').classList.remove('down');
//       }
//     }
//   } else {
//     if (document.getElementById('header').classList.contains('up') || document.getElementById('header').classList.contains('down')) {
//       document.getElementById('header').classList.remove('up');
//       document.getElementById('header').classList.remove('down');
//     }
//   }
//   lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
// }

const convertSecondsToHours = seconds => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds % 3600 / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  let result = "";
  if (hours > 0) {
    result += `${String(hours).padStart(2, "0")}:`;
  }
  result += `${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")}`;
  return result;
};
window.onbeforeunload = function () {
  // Scroll to the top of the page
  window.scrollTo(0, 0);
};
(function ($) {
  const posts_container = document.getElementById("posts-container");
  if (posts_container) {
    loadPosts();
  }
  $(".partners-logos__grid").slick({
    arrows: false,
    slidesToShow: 4,
    autoplay: true,
    responsive: [{
      breakpoint: 640,
      settings: {
        variableWidth: true
      }
    }]
  });

  // $(".fancybox").fancybox({
  //     padding: 0,
  // });

  var lastScrollPosition = window.pageYOffset;
  function scrollDown() {
    var win_width = window.innerWidth;
    var top = window.scrollY;

    //Header animation
    const headerEl = document.getElementById("header");
    //if (win_width > 600) {
    if (top > 80) {
      headerEl.classList.add("scroll");
      //headerLogo.setAttribute('src',logoData);

      var currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition > lastScrollPosition) {
        document.getElementById("header").classList.add("down");
        document.getElementById("header").classList.remove("up");
      } else {
        document.getElementById("header").classList.add("up");
        document.getElementById("header").classList.remove("down");
      }
      lastScrollPosition = currentScrollPosition;
    } else {
      headerEl.classList.remove("scroll");
      //headerLogo.setAttribute('src',logoSrc);
    }
    //}

    //Scroll animations
    var animatedEl = document.getElementsByClassName("animate-fade");
    var h = window.innerHeight;
    var i;
    for (i = 0; i < animatedEl.length; i++) {
      var viewportOffset = animatedEl[i].getBoundingClientRect();
      var topPos = viewportOffset.top;
      if (topPos <= h) {
        animatedEl[i].classList.add("fade-in");
      }
    }

    // Lazy Load
    var lazy_image = document.querySelectorAll("[data-src]");
    if (lazy_image) {
      //console.log(lazy_image);
      for (var l = 0; l < lazy_image.length; l++) {
        var data_src = lazy_image[l].dataset.src;
        var viewportOffset = lazy_image[l].getBoundingClientRect();
        var topPos = viewportOffset.top;
        if (topPos <= h * 1.3) {
          lazy_image[l].setAttribute("src", data_src);
          lazy_image[l].removeAttribute("data-src");
          lazy_image[l].classList.add("loaded");
        }
      }
    }

    // Lazy Background
    var lazy_bg = document.querySelectorAll("[data-bg]");
    if (lazy_bg) {
      //console.log(lazy_bg);
      for (var l = 0; l < lazy_bg.length; l++) {
        var data_src = lazy_bg[l].dataset.bg;
        var viewportOffset = lazy_bg[l].getBoundingClientRect();
        var topPos = viewportOffset.top;
        if (topPos <= h * 1.3) {
          lazy_bg[l].setAttribute("style", "background-image:url(" + data_src + ")");
          lazy_bg[l].removeAttribute("data-bg");
          lazy_bg[l].classList.add("loaded");
        }
      }
    }
  }
  setTimeout(function () {
    document.querySelector("body").classList.add("loaded");
  }, 800);
  scrollDown();
  window.addEventListener("scroll", function (e) {
    scrollDown();
  });
})(jQuery);
let tallestTab = 0;
const tabButtons = document.querySelectorAll(".tabs-quad__buttons a");
if (tabButtons.length > 0) {
  const tabs = document.querySelectorAll(".tab");
  for (let tab = 0; tab < tabs.length; tab++) {
    const elementHeight = tabs[tab].clientHeight;
    if (elementHeight > tallestTab) {
      tallestTab = elementHeight;
    }
    tabs[tab].classList.add("hidden");
  }
  setTimeout(() => {
    document.querySelector(".tabs-quad__inner").style.height = `${tallestTab}px`;
  }, 100);
  tabs[0].classList.remove("hidden");
  tabs[0].classList.add("active");
  for (let l = 0; l < tabButtons.length; l++) {
    tabButtons[l].addEventListener("click", event => {
      event.preventDefault();
      for (let lt = 0; lt < tabButtons.length; lt++) {
        tabButtons[lt].classList.remove("active");
      }
      tabButtons[l].classList.add("active");
      const dataTab = tabButtons[l].getAttribute("href");
      for (let m = 0; m < tabs.length; m++) {
        tabs[m].classList.remove("active");
        tabs[m].classList.add("hidden");
      }
      document.querySelector(dataTab).classList.add("active");
      document.querySelector(dataTab).classList.remove("hidden");
    });
  }
}
const video_quad_video = document.getElementById("video-quad-video");
if (video_quad_video) {
  video_quad_video.addEventListener("loadedmetadata", function () {
    const duration = video_quad_video.duration;
    document.getElementById("video-quad-time").innerHTML = convertSecondsToHours(duration);
  });
  document.querySelector(".video-quad__video-over").addEventListener("click", () => {
    video_quad_video.play();
    document.querySelector(".video-quad__video-over").style.display = "none";
  });
}
const intro_video = document.getElementById("intro-video");
if (intro_video) {
  intro_video.addEventListener("loadedmetadata", function () {
    const duration = intro_video.duration;
    document.getElementById("intro-video-time").innerHTML = convertSecondsToHours(duration);
  });
  document.querySelector(".single-product-intro__video-cover").addEventListener("click", () => {
    intro_video.play();
    document.querySelector(".single-product-intro__video-cover").style.display = "none";
  });
}
const coverage_included = document.querySelector(".product-coverage__included");
if (coverage_included) {
  coverage_included.querySelector(".open-included").addEventListener("click", () => {
    coverage_included.classList.toggle("open");
  });
}
const share_links = document.querySelectorAll(".share-links a");
if (share_links) {
  for (let s = 0; s < share_links.length; s++) {
    share_links[s].addEventListener("click", event => {
      event.preventDefault();
      const thisHref = share_links[s].href;
      window.open(thisHref, "newwindow", "width=460, height=500, top=100, left=100");
    });
  }
}
const open_menu = document.getElementById("open-menu");
if (open_menu) {
  open_menu.addEventListener("click", () => {
    document.querySelector(".header").classList.toggle("mobile-open");
  });
}
const accordionItems = document.querySelectorAll(".accordion-item");
const accordionButtons = document.querySelectorAll(".accordion-item__btn");
if (accordionButtons) {
  for (let l = 0; l < accordionButtons.length; l++) {
    accordionButtons[l].addEventListener("click", event => {
      if (accordionItems[l].classList.contains("active")) {
        accordionItems[l].classList.remove("active");
      } else {
        for (let ac = 0; ac < accordionButtons.length; ac++) {
          accordionItems[ac].classList.remove("active");
        }
        accordionItems[l].classList.add("active");
      }
    });
  }
}
const menuNavwithChildren = document.querySelectorAll("li.menu-item-has-children");
if (menuNavwithChildren) {
  const winWidth = window.innerWidth;
  for (let m = 0; m < menuNavwithChildren.length; m++) {
    let collapseButton = document.createElement("div");
    collapseButton.classList.add("nav-collapse");
    menuNavwithChildren[m].appendChild(collapseButton);
    collapseButton.addEventListener("click", () => {
      if (winWidth < 900) {
        menuNavwithChildren[m].classList.toggle("active");
      }
    });
  }
}